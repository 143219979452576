import "./projects.css";
import { useState, useLayoutEffect } from "react";
import Tournament from "../../assets/images/tournament.png";
import Metaproject from "../../assets/images/metaproject.png";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Projects = () => {
  //gsap animation

  useLayoutEffect(() => {
    const workTl = gsap.timeline();
    const ctx = gsap.context(() => {
      workTl.from(".projects-background", {
        ease: "power1.in",
        duration: 0.6,
        x: "1000",
      });
      workTl.from(".projects-heading", {
        opacity: 0,
        ease: "power1.inOut",
        duration: 0.6,
        x: "-10",
        y: "-10",
      });
      workTl.from(".projects-line", {
        opacity: 0,
        ease: "power1.in",
        duration: 0.6,
        x: "1000",
      });
      workTl.from(".code", {
        opacity: 0,
        ease: "power1.in",
        duration: 0.6,
        x: "1000",
      });
      workTl.from(".projects-scroll", {
        opacity: 0,
        ease: "power1",
        scale: 1.2,
        duration: 0.6,
        y: "-300",
      });
    });
    return () => ctx.revert();
  }, []);

  useLayoutEffect(() => {
    const tlTwo = gsap.timeline({
      scrollTrigger: {
        id: "#projects",
        start: "top top",
        end: "bottom bottom",
        ease: "sine.out",
        toggleActions: "play none none none",
        //markers: true,
      },
    });

    const ctx = gsap.context(() => {
      tlTwo.from(".project-one img", {
        opacity: 0,
        duration: 0.8,
      });
      tlTwo.from(".project-one-info", {
        opacity: 0,
        duration: 0.8,
        y: -15,
      });
      tlTwo.from(".project-two img", {
        opacity: 0,
        duration: 0.8,
        delay: 0.1,
      });
      tlTwo.from(".project-two-info", {
        opacity: 0,
        duration: 0.8,
        delay: 0.1,
        y: -15,
      });
      tlTwo.from(".project-three img", {
        opacity: 0,
        duration: 0.8,
        delay: 0.1,
      });
      tlTwo.from(".project-three-info", {
        opacity: 0,
        duration: 0.8,
        delay: 0.1,
        y: -15,
      });
    });
    return () => ctx.revert();
  }, []);

  const [showMore, setShowMore] = useState(false);

  function handleMoreClick(e) {
    e.preventDefault();
    setShowMore(!showMore);
  }

  return (
    <div id="projects">
      <div className="projects-container">
        <h1 className="projects-heading">
          Projects<span style={{ color: "#78c4c8" }}>.</span>
        </h1>
        <div className="projects-background"></div>
        <div className="projects-line"></div>
        <div className="projects-scroll">
          Scroll
          <div className="projects-scroll-line"></div>
        </div>
        <div className="code-container">
          <div className="code">
            &lt; <span style={{ color: "#222d3e" }}>/</span> &gt;
            <span style={{ color: "#222d3e" }}>.</span>
          </div>
        </div>

        <div className="projects-grid">
          <div className="project-one">
            <a href="https://tornei.mohole.it/index.html" target="_blank" rel="noreferrer">
              <img src={Tournament} alt="I Tornei - Scuola Mohole Website" />
            </a>
          </div>
          <div className="project-one-info">
            <h3>
              I T
              <span className="underline">
                ornei - Mohole 2022/2023 <span>.</span>
                <div className="highlights"></div>
              </span>
            </h3>
            <div className="project-description">
              A Static Tournament landing page in Collaboration with my Friend
              and Classmate Jether Rey Pagaduan. This page was created with
              CSS/HTML, and Vanilla.js
            </div>
            <br />
            <div className="code-info">
              <a href="#" target="_blank" rel="noreferrer">
                GitHub
              </a>
              <div> | </div>
              <a
                href="https://tornei.mohole.it/index.html"
                target="_blank"
                rel="noreferrer"
              >
                Live Site
              </a>
            </div>
          </div>
          {/* Project two */}
          <div className="project-two">
            <a href="https://metaproject.xyz/" target="_blank" rel="noreferrer">
              <img src={Metaproject} alt="Metaproject" />
            </a>
          </div>
          <div className="project-two-info">
            <h3>
              <span className="underline">
                Metaproject - Mohole  2022/2023 <span>.</span>
                <div className="highlights"></div>
              </span>
            </h3>
            <div className="project-description">
            Metaproject was born from the idea of integrating Design Thinking, prototyping techniques, programming and computer processing into a single training course aimed at the realization of innovative projects.The entire course takes place in a collaborative and protected learning environment, where students are called upon to develop transversal skills and promote a creative and multidisciplinary approach to problem solving.
            Metaproject promotes an educational dialogue made up of mutual exchanges, where voices are compared and minds are coordinated, without being limited to a one-sided monologue.
            </div>
            <br />
            <div className="code-info">
              <a href="#" target="_blank" rel="noreferrer">
                GitHub
              </a>
              <div> | </div>
              <a
                href="https://metaproject.xyz/"
                target="_blank"
                rel="noreferrer"
              >
                Live Site
              </a>
            </div>
          </div>
        </div>
      </div>
      <section id="more-projects">
        <div className="show-more">
          <button onClick={handleMoreClick}>
            <h3>{showMore ? "- Show Less Work" : "+ Show More Work"}</h3>
          </button>
        </div>
      </section>
    </div>
  );
};

export default Projects;
